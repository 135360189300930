import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./BookingPage.css";
import { useTenant } from "../TenantContext";
import { addBooking } from "../services/api";
import Loader from "./common/Loader";
import backIcon from "../assets/backIcon.svg"; // Import the back icon
import dateIcon from "../assets/dateIcon.svg"; // Import the date icon
import timeIcon from "../assets/timeIcon.svg"; // Import the time icon

import copyIcon from "../assets/copyIcon.svg"; // Import the copy icon
import PaymentPage from "./PaymentPage";
import BookingConfirmation from "./BookingConfirmation";

const BookingPage = () => {
  const tenantID = useTenant();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [PhoneNumber, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { serviceId, selectedDate, selectedTime, serviceName, price } =
    location.state;
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [bookingData, setBookingData] = useState(null);

  const handleBackClick = () => {
    navigate(-1);
  };

  const validate = () => {
    const newErrors = {};
    if (!firstName) newErrors.firstName = "الاسم الأول مطلوب";
    if (!lastName) newErrors.lastName = "اسم العائلة مطلوب";

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) newErrors.email = "البريد الإلكتروني مطلوب";
    else if (!emailPattern.test(email))
      newErrors.email = "تنسيق البريد الإلكتروني غير صالح";

    const phonePattern = /^5\d{8}$/;
    if (!PhoneNumber) newErrors.PhoneNumber = "رقم الهاتف مطلوب";
    else if (!phonePattern.test(PhoneNumber))
      newErrors.PhoneNumber =
        "يجب أن يبدأ رقم الهاتف بـ '5' وأن يكون مكونًا من 9 أرقام";

    return newErrors;
  };

  const handleBooking = async (e) => {
    e.preventDefault();

    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const updatedDate = new Date(selectedDate);
    const year = updatedDate.getFullYear();
    const month = String(updatedDate.getMonth() + 1).padStart(2, "0");
    const day = String(updatedDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}T00:00:00`;

    setBookingData({
      user: {
        firstName,
        lastName,
        email,
        PhoneNumber,
      },
      serviceId,
      bookingDate: formattedDate,
      startTime: selectedTime,
    });

    setShowPayment(true);
  };

  const handlePaymentSuccess = async () => {
    setLoading(true);

    try {
      const response = await addBooking(bookingData, tenantID);

      // Format the date and time for display
      const bookingDate = new Date(bookingData.bookingDate);
      const formattedDate = bookingDate.toLocaleDateString("ar-SA");
      const formattedTime = bookingData.startTime;

      const confirmationData = {
        tenantID: tenantID,
        formattedDate: formattedDate,
        formattedTime: formattedTime,
        bookingReference: response.reference,
        serviceInfo: {
          name: serviceName,
          price: price,
          duration: response.duration || "60",
        },
      };

      // Navigate to thanks page with booking info
      navigate("/thanks", {
        state: confirmationData,
      });
    } catch (error) {
      console.error("Error booking slot:", error);
      setErrors({ form: "خطأ في حجز الموعد" });
      setLoading(false);
      setShowPayment(false);
    }
  };

  const formattedDate = new Date(selectedDate).toLocaleDateString();
  const formattedTime = new Date(
    `1970-01-01T${selectedTime}`
  ).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  const handleCopyBookingID = () => {
    navigator.clipboard.writeText(booking.reference).then(
      () => {
        alert("تم نسخ رقم الحجز إلى الحافظة!");
      },
      (err) => {
        console.error("Could not copy booking ID: ", err);
      }
    );
  };

  return (
    <div className="booking-page-container">
      <div className="header">
        <button className="back-button" onClick={handleBackClick}>
          <img
            src={backIcon}
            alt="Back"
            style={{ width: "24px", height: "24px" }}
          />
        </button>
        <h4 style={{ marginTop: "13px" }}>{tenantID}</h4>
      </div>
      <div className="booking-page">
        {loading ? (
          <Loader />
        ) : showPayment ? (
          <div className="payment-container">
            <h2>الدفع</h2>
            <PaymentPage amount={price || 0} onSuccess={handlePaymentSuccess} />
          </div>
        ) : booking ? (
          <BookingConfirmation
            tenantID={tenantID}
            formattedDate={formattedDate}
            formattedTime={formattedTime}
            bookingReference={booking.reference}
            serviceInfo={booking.service}
            onCopyBookingID={handleCopyBookingID}
          />
        ) : (
          <form className="booking-form" onSubmit={handleBooking}>
            {errors.form && <div className="error">{errors.form}</div>}
            <label>الاسم الأول:</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            {errors.firstName && (
              <div className="error">{errors.firstName}</div>
            )}
            <label style={{ margin: "5px 0" }}>اسم العائلة:</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            {errors.lastName && <div className="error">{errors.lastName}</div>}
            <label style={{ margin: "5px 0" }}>البريد الإلكتروني:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {errors.email && <div className="error">{errors.email}</div>}
            <label style={{ margin: "5px 0" }}>الهاتف:</label>
            <input
              type="tel"
              value={PhoneNumber}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            {errors.PhoneNumber && (
              <div className="error">{errors.PhoneNumber}</div>
            )}
            <button type="submit">احجز</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default BookingPage;
